<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center" class="my-6">
      <v-col cols="12">
        <img
          src="@/assets/logo_regis.png"
          width="251"
          height="74"
          style="display: block; margin-left: auto; margin-right: auto"
        />
      </v-col>
      <v-col cols="12">
        <div class="fn20-weight7 text-center" style="color: #4f4f4f">
          Your Account has been created!
        </div>
      </v-col>
    </v-row>
    <v-card
      class="mt-6 rounded-xl"
      width="345"
      max-width="374"
      height="361"
      max-height="375"
      style="background: linear-gradient(180deg, #1a347f 0%, #202f5a 100%)"
    >
      <v-row>
        <v-col cols="12" class="mt-8">
          <img
            src="@/assets/profile.png"
            width="80"
            height="80"
            style="display: block; margin-left: auto; margin-right: auto"
          />
        </v-col>
        <v-col cols="12" class="my-1 py-0">
          <div class="white--text mx-8 fn20-weight7">Dr. Johnna Dekdee</div>
        </v-col>
        <v-col cols="12" class="my-1 py-0">
          <div class="white--text mx-8 font20">ILOVEpet Co., Ltd</div>
        </v-col>
        <v-col cols="12" class="my-1 py-0">
          <div class="white--text mx-8 font20">Pet Specialist</div>
        </v-col>
        <v-col cols="12" class="my-1 py-0">
          <div class="white--text mx-8 font20">john.dek@ilovepet.com</div>
        </v-col>
        <v-col cols="12" class="my-1 py-0">
          <div class="white--text mx-8 font20">083-XXX-X345</div>
        </v-col>
      </v-row>
    </v-card>
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        style="position: fixed; left: 0; bottom: 10px; text-align: center"
      >
        <v-btn
          class="text-capitalize color-linear rounded-lg"
          style="display: block; margin-left: auto; margin-right: auto"
          dark
          width="350"
          height="57"
          @click="submit"
          >Next</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      headersBrand: [
        // { text: "", value: "", align: "center", width: "50" },
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "รูปภาพ", value: "brandImg", align: "center" },
        { text: "ชื่อยี่ห้อ", value: "brandName", align: "center" },
        { text: "รายละเอียด", value: "description", align: "center" },
        { text: "สถานะ", value: "activeFlag", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      items: [],
      search: "",
      sortIndex: 0,
      user: "",
      loading: false,
    };
  },
  created() {
    this.user = JSON.parse(
      Decode.decode(localStorage.getItem("userBiData"))
    );
    // this.getAllbrand();
  },
  methods: {
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiData"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/brands/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAllbrand() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllbrandpe", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
      // console.log("itemBefore", this.items);
      // console.log('itemAfter',this.items);
    },
    submit() {
      this.$router.push("/");
    },
    ViewBrand(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.$router.push("ViewBrand");
    },
    async DeleteBrand(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/brands/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllbrand();
        }
      });
    },
    goToCreate() {
      this.$router.push("createbrand");
    },
  },
};
</script>

<style scoped>
.div-bottom {
  position: fixed;
  left: 0;
  bottom: 130px;
  width: 100%;
  text-align: center;
}
.btn-bottom {
  position: fixed;
  left: 0;
  bottom: 30px;
  text-align: center;
}
.color-linear {
  background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);
}
</style>
